.App {
  display: flex;
  flex-direction: column;
  margin-top: 10vh;
}

.App-identifyButton {
  font-size: 16px;
  border-radius: 4px;
  background-color: #FF5D22;
  border: none;
  color: #fff;
  align-self: center;
  padding: 8px 16px;
  cursor: pointer;
  transition: box-shadow 0.2s ease;
}

.App-identifyButton:hover {
  box-shadow: 0 0 0 2px rgba(255, 93, 34, 0.5);
}

.App-identifyButton:active {
  box-shadow: 0 0 0 2px rgb(255, 93, 34);
}

.App-userData {
  margin-top: 32px;
  max-width: 600px;
  align-self: center;
  background-color: rgba(211, 211, 211, 0.51);
  padding: 16px 32px;
}
